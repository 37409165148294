import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Caption = makeShortcode("Caption");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "exporting-a-resource-saves-data-in-a-different-format-external-to-the-system"
    }}>{`Exporting a resource saves data in a different format, external to the system.`}</h3>
    <h4 {...{
      "id": "status"
    }}>{`Status:`}</h4>
    <p>{`Experimental`}</p>
    <h4 {...{
      "id": "maintainers"
    }}>{`Maintainers:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.com/vikkipaterson"
      }}>{`Vikki Paterson`}</a></p>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Intuitive default name</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Editable name</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Specify export location</AnchorLink>
    </AnchorLinks>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "51.42857142857144%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB5ElEQVQoz32QT2sTURTF81mkuO3GgosggpCYpimaaJtSqFCTSeJGKoJLl8FOJvM/M5OZJFSQkPQrBZ0Kgpm0iprJpLo4vvuSqSmKi8N7c++5vzPvJsLpFAdP9vGwUMCDfAHZXA7ZrRzyhUfYKe5hZ7d4pcd0Fou8t8k85N3a3sb9zSx29/YxDUMkzi++4M7de6hWqyiXBX5WKhUIgoBSqYSnh4esXoawFL+zXq1WwzMmOql+9OIlZrMIiclkghxLMls2bMeF0/bgui46nQ76/T4GgwGGwyHTKYanS7F7r9fjPsdxYNs2ut0u5vP5ApjJZKBpGmS5ydVoNOB5HsbjMaIoQsSM0SxEFH7D5ew7wh9f8fbkBMfHIppNmpE5mLyJIAiQTqc5UJIkLlEUuWE0GsH3fXw883H26QJ+8Asfxpd4/3kOr0vAN9xP0Ha7/QeYSqVgGAZM04Su62i1WqjX60gmk7i1cRsb6zexnhex9uonbjwPsHZ0jtfSO9imCt1YzPwFpCJBCWZZFodTMklVFCiaCUm1IDYNiLIBVdX4q0gK69PMtSfHQFpwvOgYTsv33DYs04CmyNBUZRHCpKoq3+E1IP0hJVExFsEo4KrGAnT2TRCFQUjqUv8Exmmr+l9ttbcK/A2rgyQtfZlEuAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of an export pattern using a modal in context",
        "title": "Example of an export pattern using a modal in context",
        "src": "/static/19ce8a9a87d5d6425aeb6fe8f04fa042/fb070/contextual.png",
        "srcSet": ["/static/19ce8a9a87d5d6425aeb6fe8f04fa042/d6747/contextual.png 288w", "/static/19ce8a9a87d5d6425aeb6fe8f04fa042/09548/contextual.png 576w", "/static/19ce8a9a87d5d6425aeb6fe8f04fa042/fb070/contextual.png 1152w", "/static/19ce8a9a87d5d6425aeb6fe8f04fa042/fb104/contextual.png 1728w", "/static/19ce8a9a87d5d6425aeb6fe8f04fa042/8fefe/contextual.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of a export pattern using a modal</Caption>
    <h2 {...{
      "id": "intuitive-default-name"
    }}>{`Intuitive default name`}</h2>
    <p>{`When the resource is given an intuitive name by default and there is no choice of export location, the export occurs on the click of the Export button and nothing additional is displayed.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "44.285714285714285%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABJElEQVQoz51SW0rEQBDMbcWTLB5A8M8D+K8X8AzqLpiweUwm73cyE0hKa9ZhiQrKNjQ1011T3UXi4CvWdf13nmPd5PIJzpbwd5A/zzMmNeP+Gbh5AnaPwHt6GuBYkhACnudBSonj8WgyjmO4rosoikyyH4YRDoc9Xl732D0IXN16uL4L8BbNZuCvgr7vGzHWeGYtDEMEQYAkScxZyhi6k1B1gHVMgEUb4461UlUVsixD13UoigJ936NpGpRliWEYDPLOuu1neQk/FBAyhVL6vCGDpDRN0bYt8jw3yCGs2yG8s24Hc3O6opNpmraWuQHtcAs+IFLAilOQHCvIPmt8U9e1+VCbDTmB1rTWGMfRoFLKnEkmkvO9zzfEZVlOgj//rcuDOh/0qrLzWrhu1AAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of an export button on a data table",
        "title": "Example of an export button on a data table",
        "src": "/static/6bd8b25970bbb3d002fcb9b41297ddc4/fb070/1.png",
        "srcSet": ["/static/6bd8b25970bbb3d002fcb9b41297ddc4/d6747/1.png 288w", "/static/6bd8b25970bbb3d002fcb9b41297ddc4/09548/1.png 576w", "/static/6bd8b25970bbb3d002fcb9b41297ddc4/fb070/1.png 1152w", "/static/6bd8b25970bbb3d002fcb9b41297ddc4/fb104/1.png 1728w", "/static/6bd8b25970bbb3d002fcb9b41297ddc4/8fefe/1.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "editable-name"
    }}>{`Editable name`}</h2>
    <p>{`When exporting with an editable name, a dialog should be presented to the user displaying an editable textbox that is pre-populated with the default name for the file.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.733695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABS0lEQVQoz6WSMU+DQBTHSVzUzThqBBv9Bk4O6GDiYpQ2OrtoZHL2C2gdHP2CTEWKBe4olLtyf+9AWkJpbeIlv/zfwbv/PR5PE0JALaX/ofLQqqBpuu6qn1VLaz5oS1xVVTNXq78MggCu64JSivF4/CdxHBe5Sw0HgwEcxymS1oEQioiQRcNqo271fR+j0QhZlmEymfwi44y1kiQp8jxvr5AxhjRNCzMVl3BwJvcpAUtC8DSqEWKa0fYKVT9s20av10O324VlWRKpN1e4vrvH5ZuHiw/A7DOc9TnO3zlOXzluP6cIk9IjrxsS2QvTNNHpdGAYBnRdh24cwjjYw/7xCXYfXew8A5tPAls2sC3ZeACOXgCfFvVJw8bYqP55nlfojOFQ6jfccAo3AjwiZnxF8gwVRWWtg73GGK8YcCwOtvpbSxHlJ811TmWm9AfX+X5m/AiFiQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of an export modal",
            "title": "Example of an export modal",
            "src": "/static/3dec955dbf907a6b3894164da8acb23f/fb070/2.png",
            "srcSet": ["/static/3dec955dbf907a6b3894164da8acb23f/d6747/2.png 288w", "/static/3dec955dbf907a6b3894164da8acb23f/09548/2.png 576w", "/static/3dec955dbf907a6b3894164da8acb23f/fb070/2.png 1152w", "/static/3dec955dbf907a6b3894164da8acb23f/c3e47/2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.733695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAAA/0lEQVQoz6WSSW6DQBBFuQcLhiuw5wRIwAWcLUeOEnkRsWG2xDz/+CM1IjZYWCnp6Td01ae6KWmeZzCo/0F4SGLxaPpuiBppa7aX8HaH2xdZliEIAhRFgaqqTlHX9bFhGIbwfR95ni+mVLE+oizLZ0PGNE1o2xZd16FpGvR9/4dhGFbGcVxhDWufDKlMZjETmSTgc705JrsiLzvkhud5cF0XjuPAtu1FLcvCx+WC7+sPbrcb4jher4IcGnLTNE3oug5VVaEoCjRNgyzLMAwDn1/XxTCKwrUz8fN2DalJktwLokUF7ChNU0z3Y4vYXsfLwT4zvEe5u4O9/fIjZwf7F/WBkL3UoZ09AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of an export modal in its \"Exporting\" state.",
            "title": "Example of an export modal in its \"Exporting\" state.",
            "src": "/static/0c48bcd3dbbcdf831a643c6099510493/fb070/3.png",
            "srcSet": ["/static/0c48bcd3dbbcdf831a643c6099510493/d6747/3.png 288w", "/static/0c48bcd3dbbcdf831a643c6099510493/09548/3.png 576w", "/static/0c48bcd3dbbcdf831a643c6099510493/fb070/3.png 1152w", "/static/0c48bcd3dbbcdf831a643c6099510493/c3e47/3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.733695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAAA/ElEQVQoz6VSSYqEQBD0Hx5cvuDdFwjqBzz75+lhuvHirrjvMWRBDvZiYzMJQZRWRlRUkdK2baAi/g/YQ+LFo+mnxRppb/aq4eOE+x95niMMQ9R1jbZtT6HrumPDKIoQBAGqqhKmxLw+QtM0z4ZU67piGAaM44i+7zFN0x3meRZYluUOpCHtkyExCUhMjdTEoG++IiXiZG8T0obv+3BdF47jwLZtwZZlwfM8/FxvKIoSZVn8PQXh0JA2TdOErutQVRWKokDTNMiyDMMw8HX5RlGSYSlMOCGlfmlInKYp4jgWzEiSBFmWiWtz3/453g722eE9Pdj7kx9xdrB/ASp6kOqpQDcxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of an export modal in its \"Success\" state.",
            "title": "Example of an export modal in its \"Success\" state.",
            "src": "/static/e5a88bf7fe27a11539c0863da17530a0/fb070/3.5.png",
            "srcSet": ["/static/e5a88bf7fe27a11539c0863da17530a0/d6747/3.5.png 288w", "/static/e5a88bf7fe27a11539c0863da17530a0/09548/3.5.png 576w", "/static/e5a88bf7fe27a11539c0863da17530a0/fb070/3.5.png 1152w", "/static/e5a88bf7fe27a11539c0863da17530a0/c3e47/3.5.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "specify-export-location"
    }}>{`Specify export location`}</h2>
    <p>{`When specifying the download location of a resource, clicking the primary “download” button activates the browser’s default location panel. The file is downloaded once a location is selected and the user clicks Save.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.02173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAAC80lEQVQ4y62U208TeRTHS0AjCNJCW0rBtNIlpNPL2GmlWHqB0lJb6i7Ue4JueDAFEiHByyaGwAO8ER7gLzBedjXLCoai8ZLNbmJMNhtf/Av8H7hDwsffzCj7so9OcuY358ycz/me8/tlDAcHB3zPy6De/vn0macvP/Jb6V8eLb/nybMV/lh5xcrqG16U3rL25m9Kr/9iufSOZRH7ffkVj35d4uHSnzx4/oGnz5ZYK5XY29vTgUP3H3NcmaCu4x7H5WGcgX5coQJN3jynggV8ySHcsRu0Ra8L/wJORZic54R8kyO+W9TWNxIKBtjY2NCBv9y+hdNupNVhIeRvJZ8/T28mS1d3img0Tu+5HD3JHrq7eziXzdObzpCId9PXm+DGlRwXL/RTLN5ke3tbB96+c48Gm51GezPB4BmuXb1KMplECZzhtKwQF8kd4QiBYAdd6Ryy7zRej5++vjzF4WGGhn7m7t077Ozs6MCJiQnMZjMWiwWPJJHLZumMRPB/TezqyRCPdRFQ2oknMwREEa9HplAocOXyJX5oaWF0dJT9/X0dOD4+jtFoFECrUCSTSqWE0pCmzuf1k0hliSdUxXpM9it43BIDAwOEgwpHDQZ+zOf/A6r0qqoqbDYbTodTqIuK5KCmJCRGoM7vp/6CGEOaSHuQWOdZYok06UwO58lmXA6HNvOtra8zHBsb0xTa7XYarA20tbaRiCU0mM/rI6TIxCPtRMJhOjrDRAVQUpK0eUK4XC5sjc10is3b3NrSgcVikcrKSpqamjAZTZSXVSC53YTOhkWLXuJRodYn0WxrICxikuSh6tgxLGYLdXVmDKJlye1hc3NTB46MjFBdXY1DSK+traW8vFxbTSaTVqii4oi2qrH6+nrN1I7UHNVUYIvYmMNzODg4qAW/vfw/KysrOzwJVqtVW2tqag5z1GLr6+s6cHV1lZmZGebn55mdnWVqaorJyUnNpqenNX9ubo6FhQXtm2+m+ouLi4fPu7u7GL73z+ELT4h8dM3gvjAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of an export modal in its \"Exporting\" state.",
            "title": "Example of an export modal in its \"Exporting\" state.",
            "src": "/static/59c34975965f6a7513bee819b4b73056/fb070/4.png",
            "srcSet": ["/static/59c34975965f6a7513bee819b4b73056/d6747/4.png 288w", "/static/59c34975965f6a7513bee819b4b73056/09548/4.png 576w", "/static/59c34975965f6a7513bee819b4b73056/fb070/4.png 1152w", "/static/59c34975965f6a7513bee819b4b73056/c3e47/4.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      